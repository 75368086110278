import { DatePipe, JsonPipe, KeyValuePipe, TitleCasePipe, UpperCasePipe } from "@angular/common";
import { Component, Injector, OnInit, computed, effect, inject, runInInjectionContext, signal, viewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule, NgControl, Validators } from "@angular/forms";
import {
    Address,
    AddressType,
    ContactType,
    DirectoryClient,
    DirectoryEntryQueryResult,
    DirectoryEntrySource,
    DirectoryEntryType,
    Governance,
    InitiatorOnlyType,
    PurposeOfUse,
    ResponderFilter
} from "@kno2/data-access/directory";
import { CommonDataToken } from "@kno2/interop";
import { AdminDocumentSourcesService } from "@kno2/ng1/admin/documentsources/admin-documentsources.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime, distinctUntilChanged, firstValueFrom } from "rxjs";
import { BootstrapValidationCssDirective } from "../../../../common/directives/bootstrap-validation-css/bootstrap-validation-css.directive";

@Component({
    selector: "admin-organizations-qhin-details-modal",
    standalone: true,
    imports: [FormsModule, DatePipe, TitleCasePipe, JsonPipe, UpperCasePipe, KeyValuePipe, BootstrapValidationCssDirective],
    templateUrl: "./admin-organizations-qhin-details-modal.component.html"
})
export class AdminOrganizationsQhinDetailsModalComponent implements OnInit {
    DirectoryEntryType = DirectoryEntryType;
    InitiatorOnlyType = InitiatorOnlyType;
    ResponderFilters = Object.values(ResponderFilter);

    public organizationId = signal<number>(null);
    public directoryEntry = signal<DirectoryEntryQueryResult>(null);
    public isNewEntry = signal(false);
    public hasQhinQueryEnabled = signal(false);

    protected allowEditOid = signal(false);
    protected allowSelectInitiatorOnlyType = signal(false);
    protected allPurposesOfUse = signal<PurposeOfUse[]>([]);
    protected purposesOfUse = signal<PurposeOfUse[]>([]);
    protected documentSources = signal<string[]>([]);

    protected directoryEntryOrganization = computed(() => this.directoryEntry()?.contacts?.find((c) => c.contactType === ContactType.Organization) || {});
    protected directoryEntryOrganizationAddress = computed(
        () => this.directoryEntryOrganization().addresses?.find((c) => c.addressType === AddressType.OrganizationLocation) || ({} as Address)
    );
    protected directoryEntryResponderFilter = computed(() => this.directoryEntry()?.responderFilter || null);

    protected activeModal = inject(NgbActiveModal);
    protected commonData = inject(CommonDataToken);
    private directoryClient = inject(DirectoryClient);
    private documentSourcesService = inject(AdminDocumentSourcesService);
    private injector = inject(Injector);
    private originalResponderFilter: ResponderFilter;

    protected parentDirectoryOidFormControl = viewChild<NgControl>("parentDirectoryOidFormControl");

    private generateOidEffect = effect(
        async () => {
            if (!this.directoryEntry().id) {
                const newOid = await firstValueFrom(
                    this.directoryClient.newOidRequest({ kno2OrganizationId: this.organizationId(), source: DirectoryEntrySource.Rce })
                );

                this.directoryEntry().id = newOid;
                this.directoryEntry().homeCommunityId = newOid.replace(/^rce:urn:oid:/, "");
                this.generateOidEffect.destroy();
            }
        },
        { allowSignalWrites: true }
    );

    private updateResponderAndInitiatorValuesEffect = effect(() => {
        const allowInitiatorOnly = this.allowSelectInitiatorOnlyType();

        this.directoryEntry().initiatorOnlyType = allowInitiatorOnly ? this.directoryEntry().initiatorOnlyType : null;
        this.directoryEntry().responderFilter = allowInitiatorOnly ? null : this.handleResponderFilterLogic(this.originalResponderFilter);
    });

    protected canDisplayResponderFilters(): boolean {
        return (
            !this.allowSelectInitiatorOnlyType() &&
            (this.directoryEntry().directoryEntryType === DirectoryEntryType.Subparticipant ||
                this.directoryEntry().directoryEntryType === DirectoryEntryType.Participant)
        );
    }

    protected onOidChange(): void {
        const currentId = this.directoryEntry().id;
        if (currentId) {
            this.directoryEntry().homeCommunityId = currentId.replace(/^rce:urn:oid:/, "");
        }
    }

    async ngOnInit(): Promise<void> {
        const directoryEntry = this.directoryEntry();
        this.allowSelectInitiatorOnlyType.set(!!directoryEntry.initiatorOnlyType);
        this.originalResponderFilter = this.directoryEntry().responderFilter;

        // Todo: awaiting this causes fuckery because it's a QPromise :sad:
        this.documentSourcesService
            .getDocumentSources("asc", 0, 50, "Activated", "DirectMessage", this.organizationId())
            .then((r) => this.documentSources.set(r.documentSources.map((m) => m.address)));

        this.allPurposesOfUse.set(await firstValueFrom(this.directoryClient.purposesOfUse(directoryEntry.governance)));
        await this.updatePurposeOfUseCodesFromDirectoryEntry(directoryEntry.parentId);

        if ([DirectoryEntryType.Child, DirectoryEntryType.Subparticipant].includes(directoryEntry.directoryEntryType)) {
            runInInjectionContext(this.injector, () => {
                this.parentDirectoryOidFormControl()
                    .valueChanges.pipe(debounceTime(500), distinctUntilChanged(), takeUntilDestroyed())
                    .subscribe(this.updatePurposeOfUseCodesFromDirectoryEntry.bind(this));

                this.parentDirectoryOidFormControl().control.addValidators(Validators.required);
                this.parentDirectoryOidFormControl().control.updateValueAndValidity();
            });
        }
    }

    public async saveDirectoryEntry() {
        const directoryEntry = this.directoryEntry();
        const directoryEntryOrganizationAddress = this.directoryEntryOrganizationAddress();
        const directoryEntryResponderFilter = this.directoryEntryResponderFilter();

        const request = {
            city: directoryEntryOrganizationAddress.city,
            country: directoryEntryOrganizationAddress.country,
            governance: directoryEntry.governance,
            homeCommunityId: directoryEntry.homeCommunityId,
            responderFilter: directoryEntryResponderFilter,
            id: directoryEntry.id,
            inboundMessageAddress: directoryEntry.inboundMessageAddress,
            initiatorOnlyType: directoryEntry.initiatorOnlyType,
            name: directoryEntry.name,
            parentId: directoryEntry.parentId,
            purposesOfUse: directoryEntry.purposeOfUseCodes,
            source: directoryEntry.source,
            state: directoryEntryOrganizationAddress.state,
            street1: directoryEntryOrganizationAddress.street1,
            type: directoryEntry.directoryEntryType,
            zipCode: directoryEntryOrganizationAddress.zipCode
        };

        if (this.isNewEntry()) {
            await firstValueFrom(
                this.directoryClient.directoryEntriesPOST({
                    ...request,
                    source: DirectoryEntrySource.Rce,
                    governance: Governance.Tefca,
                    kno2OrganizationId: directoryEntry.kno2OrganizationId
                })
            );
        } else {
            await firstValueFrom(
                this.directoryClient.directoryEntriesPUT(directoryEntry.id, {
                    ...request,
                    isActive: directoryEntry.isActive
                })
            );
        }

        this.activeModal.close();
    }

    private async updatePurposeOfUseCodesFromDirectoryEntry(directoryId?: string) {
        if (!directoryId) return;

        let directoryEntry: DirectoryEntryQueryResult;
        try {
            directoryEntry = await firstValueFrom(this.directoryClient.directoryEntriesGET(directoryId));
        } catch (e) {}

        const filteredPurposesOfUse = directoryEntry ? this.allPurposesOfUse().filter((f) => directoryEntry.purposeOfUseCodes.includes(f.code)) : [];

        this.purposesOfUse.set(filteredPurposesOfUse);
    }

    private handleResponderFilterLogic(originalResponderFilter?: ResponderFilter) {
        if (this.hasQhinQueryEnabled() || this.directoryEntry().isParticipatingResponder) {
            return originalResponderFilter || ResponderFilter.Hrr;
        }
        return null;
    }
}
